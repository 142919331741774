import { extendTheme } from "@chakra-ui/react";
import chroma from "chroma-js";

export const updatePressTheme = (colors, theme) =>
  extendTheme(
    {
      colors: {
        main: {
          800: colors.mainColor || theme?.colors?.main?.[800],
          700:
            (colors.mainColor &&
              chroma(colors.mainColor).brighten(0.8).hex()) ||
            theme?.colors?.main?.[700],
          600:
            (colors.mainColor &&
              chroma(colors.mainColor).brighten(1.5).hex()) ||
            theme?.colors?.main?.[600],
        },
        light: {
          200:
            (colors.bgColor && chroma(colors.bgColor).darken(0.35).hex()) ||
            theme?.colors?.light?.[200],
          100: colors.bgColor || theme?.colors?.light?.[100],
        },
        dark: {
          800: colors.boxColor || theme?.colors?.dark?.[800],
        },
        text: {
          900: colors.textColor || theme?.colors?.text?.[900],
          200:
            (colors.boxColor &&
              chroma.mix("#FFFFFF", colors.boxColor, 0.25, "hsl").hex()) ||
            theme?.colors?.text?.[200],
        },
      },
    },
    theme
  );

export const updatePanelTheme = (colors, theme) =>
  extendTheme(
    {
      colors: {
        bg: {
          900: colors.bgColor || theme?.colors?.bg?.[900],
          600:
            (colors.bgColor && chroma(colors.bgColor).brighten(0.35).hex()) ||
            theme?.colors?.bg?.[600],
        },
        main: {
          900:
            (colors.mainColor && chroma(colors.mainColor).darken(0.15).hex()) ||
            theme?.colors?.main?.[900],
          800: colors.mainColor || theme?.colors?.main?.[800],
          600:
            (colors.mainColor &&
              chroma(colors.mainColor).brighten(0.25).hex()) ||
            theme?.colors?.main?.[600],
          500:
            (colors.mainColor &&
              chroma(colors.mainColor).brighten(1.5).hex()) ||
            theme?.colors?.main?.[500],
        },
        light: {
          300:
            (colors.boxColor &&
              colors.mainColor &&
              chroma
                .mix(colors.boxColor, colors.mainColor, 0.08, "hsl")
                .hex()) ||
            theme?.colors?.light?.[300],
          200:
            (colors.boxColor &&
              colors.mainColor &&
              chroma.mix(colors.boxColor, colors.mainColor, 0.1).hex()) ||
            theme?.colors?.light?.[200],
          100: colors.boxColor || theme?.colors?.light?.[100],
          50:
            (colors.boxColor &&
              colors.mainColor &&
              chroma
                .blend(colors.boxColor, colors.mainColor, "lighten")
                .hex()) ||
            theme?.colors?.light?.[50],
          20:
            (colors.boxColor &&
              colors.mainColor &&
              chroma
                .blend(colors.boxColor, colors.mainColor, "lighten")
                .brighten(0.25)
                .hex()) ||
            theme?.colors?.light?.[50],
        },
        text: {
          900: colors.textColor || theme?.colors?.text?.[900],
          800:
            (colors.textColor &&
              colors.mainColor &&
              chroma
                .mix(colors.textColor, colors.mainColor, 0.1)
                .brighten(1)
                .hex()) ||
            theme?.colors?.text?.[800],
          700:
            (colors.textColor &&
              chroma(colors.textColor).brighten(2.5).hex()) ||
            theme?.colors?.text?.[700],
          500:
            (colors.textColor &&
              chroma(colors.textColor).brighten(3.25).hex()) ||
            theme?.colors?.text?.[500],
          400:
            (colors.textColor && chroma(colors.textColor).brighten(4).hex()) ||
            theme?.colors?.text?.[400],
          100:
            (colors.mainColor &&
              chroma.mix("#FFFFFF", colors.mainColor, 0.35).hex()) ||
            theme?.colors?.text?.[100],
        },
      },
    },
    theme
  );
