import { File, Tag, Folder } from "./cloud";
import { Event } from "./event";
import { User, UserInvitationStatuses } from "./user";
import { CampaignRecipients } from "./newsletter";

export enum OfficeUserRole {
  REPORTER = "reporter",
  ADMIN = "admin",
  MOD = "mod",
}

export enum OfficeType {
  ORGANIZATION = "organization",
  EDITORIAL = "editorial",
  PRESS = "press",
}

export type EditorialOffice = {
  id: number;
  slug?: string;
  activated: boolean;
  verified: boolean;
  type: string;
  name: string;
  email?: string;
  phone?: string;
  picture?: string;
  url?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  position?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  twitch?: string;
  representative?: string;
  officeRequest?: {
    id: number;
    type: "invitation" | "request";
    status: UserInvitationStatuses;
  };
};

export type PressOffice = {
  activated?: boolean;
  id: number;
  main: boolean;
  editorialType: OfficeType;
  name: string;
  slug?: string;
  url?: string;
  email?: string;
  invoiceName?: string;
  invoiceEmail?: string;
  invoiceAddress?: string;
  invoiceCity?: string;
  invoiceCountry?: string;
  invoicePostalCode?: string;
  phone?: string;
  position?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  picture?: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  twitch?: string;
  linkedin?: string;
  tiktok?: string;
  otherSocial?: string;
  createdAt: Date;
  updatedAt: Date;
  representative?: string;
  nip?: number;
  organization?: Organization;
};

export type ReporterGroup = {
  id: number;
  pressOffice: PressOffice;
  name: string;
  description: string;
  color: string;
};

export type ReporterGroupOrEventOrSubscribersCategory =
  | (ReporterGroup & { picture?: undefined; type?: undefined })
  | (Event & { color?: undefined })
  | (SubscriberCategory & {
      type?: string;
      color?: undefined;
      description?: undefined;
      picture?: undefined;
    });

export const officeRoleOptions = [
  {
    label: "admin",
    value: "admin",
  },
  {
    label: "mod",
    value: "mod",
  },
  {
    label: "reporter",
    value: "reporter",
  },
];

export enum ArticleType {
  PRESS_NOTE = "pressNote",
  DRAFT = "draft",
}

export type Article = {
  id?: number;
  author: User;
  pressOffice: PressOffice;
  slug?: string;
  title: string;
  publicationDate: Date;
  blockedBySubscriptionLimit: boolean;
  tags: Tag[];
  toSubscribers: boolean;
  reporterGroups: ReporterGroup[];
  mailingGroups?: string[];
  events: Event[];
  organizations?: Organization[];
  subscribersCategories?: SubscriberCategory[];
  toAdminsAndMods?: boolean;
  type: ArticleType;
  webTopic?: { [locale: string]: string };
  webContent?: { [locale: string]: string };
  webShortDescription?: { [locale: string]: string };
  webFile?: File;
  smsSender?: string;
  smsContent?: string;
  emailTopic?: { [locale: string]: string };
  emailContent?: { [locale: string]: string };
  emailFiles?: File[];
  emailFolders?: Folder[];
  twitterContent?: string;
  twitterFiles?: File[];
  facebookContent?: string;
  facebookFiles?: File[];
  createdAt?: Date;
  updatedAt?: Date;
  campaignCreated?: boolean;
  campaignRecipients?: CampaignRecipients[];
  sentOk?: number;
  sentError?: number;
  sentAll?: number;
};

export type ArticleInputs = Omit<
  Article,
  "reporterGroups" | "events" | "createdAt" | "updatedAt"
>;

export type Regulation = {
  id?: number;
  name: string;
  content: string;
  url: string;
  file: string;
};

export enum ActivationStatuses {
  "PENDING" = "pending",
  "GRANTED" = "granted",
  "REJECTED" = "rejected",
}

export type Organization = {
  id?: number;
  name?: string;
  email?: string;
  picture?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  nip?: string;
};

export type SubscriberCategory = {
  id: number;
  name: string;
  pressOffice: PressOffice;
  pressOfficeId: number;
  subscribers: User[];
};
