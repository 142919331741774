import { extendTheme } from "@chakra-ui/react";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const overrides = {
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Roboto', sans-serif",
  },
  colors: {
    bg: {
      900: "#1c3faa",
      600: "#2e51bb",
    },
    main: {
      900: "#1a389f",
      800: "#1c3faa",
      700: "#274ab7",
      600: "#2e51bb",
      500: "#b5c8ff",
    },
    light: {
      300: "#e2e8f0",
      200: "#dee7ef",
      100: "#f1f5f8",
      50: "#edf2f7",
      20: "#f7fafc",
      10: "#FFFFFF",
    },
    text: {
      900: "#2d3748",
      800: "#365a74",
      700: "#4a5568",
      500: "#718096",
      400: "#a0aec0",
      100: "#c7d2ff",
    },
    red: {
      600: "#B92424",
      500: "#d32929",
      400: "#e34545",
    },
    green: {
      500: "#91c714",
      400: "#9ccc2d",
    },
    orange: {
      500: "#fbc500",
    },
    blue: {
      500: "#1c3faa",
    },
  },
  styles: {
    global: {
      html: {
        overflowX: "hidden",
      },
      body: {
        bg: "bg.900",
        fontSize: ".875rem",
        lineHeight: "1.25rem",
        color: "text.900",
        overflowX: { base: "hidden", md: "unset" },
      },
      ".parsed-html": {
        "ul, ol": {
          marginLeft: { base: "25px", md: "30px" },
        },
        a: {
          color: "main.800",
          fontWeight: "bold",
          _hover: {
            color: "text.900",
          },
        },
        h1: {
          fontSize: "2.25rem",
          lineHeight: 1.33,
        },
        h2: {
          fontSize: "1.875rem",
          lineHeight: 1.33,
        },
        h3: {
          fontSize: "1.5rem",
          lineHeight: 1.33,
        },
        h4: {
          fontSize: "1.25rem",
          lineHeight: 1.33,
        },
        h5: {
          fontSize: "1.125rem",
          lineHeight: 1.33,
        },
        blockquote: {
          borderLeftWidth: 5,
          borderColor: "main.800",
          paddingLeft: "30px",
        },
        ".ql-align-center": {
          textAlign: "center",
          "img, iframe": {
            marginLeft: "auto",
            marginRight: "auto",
          },
        },
        'div[id^="audio-"].ql-align-center, div[id^="video-"].ql-align-center':
          {
            marginLeft: "auto",
            marginRight: "auto",
          },
        ".ql-align-justify": {
          textAlign: "justify",
        },
        ".ql-align-right": {
          textAlign: "right",
          "img, iframe": {
            display: "inline-block",
          },
        },
        'div[id^="audio-"].ql-align-right, div[id^="video-"].ql-align-right': {
          marginLeft: "auto",
        },
        'div[id^="tweet-"]': {
          width: "calc(100% - 50px)",
          ".twitter-tweet": {
            display: "inline-flex !important",
          },
        },
        ".ql-align-left": {
          textAlign: "left",
        },
        ".ql-indent-1": {
          marginLeft: "30px",
        },
        ".ql-indent-2": {
          marginLeft: "60px",
        },
        ".ql-indent-3": {
          marginLeft: "90px",
        },
        ".ql-indent-4": {
          marginLeft: "120px",
        },
        ".ql-indent-5": {
          marginLeft: "150px",
        },
        ".large": {
          width: "100%",
        },
        ".medium": {
          width: "60%",
        },
        ".small": {
          width: "30%",
        },
      },
      img: {
        maxWidth: "100%",
      },
      a: {
        transition: "all .2s cubic-bezier(.4,0,.2,1)",
        _hover: { textDecoration: "none" },
        img: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
        span: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
        svg: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
      },
      "a.hashtag-link": {
        color: "main.800",
        fontWeight: "500",
        _hover: {
          color: "text.900",
        },
      },
      select: {
        height: "auto !important",
      },
      "label[data-disabled], label[disabled]": {
        cursor: "default !important",
      },
      ".chakra-menu__icon-wrapper": {
        svg: {
          width: "1rem",
          height: "1rem",
        },
      },
      ".chakra-slider__thumb": {
        fontSize: "12px !important",
      },
      "h2:first-of-type": {
        mt: 0,
      },
      "button svg": {
        width: "16px",
        height: "16px",
      },
      "::-webkit-input-placeholder": {
        color: "text.400",
      },
      ":-moz-placeholder": {
        color: "text.400",
        opacity: 1,
      },
      "::-moz-placeholder": {
        color: "text.400",
        opacity: 1,
      },
      ":-ms-input-placeholder": {
        color: "text.400",
      },
      ".react-datepicker-popper": {
        pt: ".25rem",
        width: { base: "250px", md: "340px" },
        zIndex: 4,
      },
      ".react-datepicker__triangle": {
        display: "none",
      },
      ".react-datepicker": {
        fontFamily: "inherit",
        fontSize: "13px",
        borderColor: "red",
        boxShadow: "0 3px 10px rgb(0, 0, 0, 0.09)",
        borderWidth: 0,
        borderRadius: ".375rem",
        p: ".5rem",
        color: "text.900",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { base: "column", md: "row" },
        mb: 4,
      },
      ".react-datepicker-wrapper, .react-datepicker__input-container": {
        display: "block",
      },
      ".react-datepicker__input-container": {
        fontSize: ".875rem",
        padding: 0,
        border: 0,
        lineHeight: "1.15",
        "> div": {
          transition: "border-color .2s cubic-bezier(.4,0,.2,1)",
          borderRadius: ".375rem",
          border: "1px solid",
          borderColor: "light.300",
          padding: ".5rem .75rem",
        },
      },
      ".react-datapicker__input-text": {
        backgroundColor: "transparent",
        width: "100%",
        outline: "none",
      },
      ".react-datepicker__input-container > div:focus-within": {
        zIndex: 1,
        boxShadow: "none",
        "&:not(.readonly)": {
          borderColor: "main.600",
        },
      },
      "label[data-invalid] + .react-datepicker-wrapper .react-datepicker__input-container > div":
        {
          borderColor: "red.500",
        },
      ".react-datepicker__input-container > input": {
        width: "100%",
        height: "100%",
        outline: 0,
      },
      ".react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)":
        {
          right: { base: "2px", md: "90px" },
        },
      ".react-datepicker__navigation *::before": {
        borderColor: "text.500",
      },
      ".react-datepicker__navigation:hover *::before": {
        borderColor: "text.900",
      },
      ".react-datepicker__header": {
        backgroundColor: "white",
      },
      ".react-datepicker__navigation": {
        top: ".5rem",
      },
      ".react-datepicker__header, .react-datepicker__time-container": {
        borderColor: "white",
        py: ".5rem",
      },
      ".react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header":
        {
          fontWeight: 500,
          color: "text.900",
          fontSize: "15px",
          pb: ".25rem",
        },
      ".react-datepicker__month": {
        backgroundColor: "white",
        margin: 0,
      },
      ".react-datepicker__time-container": {
        w: { base: "100%", md: "85px" },
        pt: 0,
        ".react-datepicker-time__header": {
          pb: 0,
        },
        ".react-datepicker__time .react-datepicker__time-box": {
          w: { base: "100%", md: "85px" },
        },
      },
      ".react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item":
        {
          margin: "0 1px 0 0",
          height: "auto",
          padding: "5px 7px",
          fontSize: "12px",
          borderRadius: ".375rem",
        },
      ".react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover":
        {
          background: "light.100",
        },
      ".react-datepicker__day": {
        color: "text.900",
        borderRadius: ".375rem",
      },
      ".react-datepicker__day:hover": {
        background: "light.100",
      },
      ".react-datepicker__day-name": {
        color: "text.900",
        fontWeight: 500,
      },
      ".react-datepicker__day--selected, .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected":
        {
          background: "main.800",
          fontWeight: "normal",
          color: "light.10",
        },
      ".react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover, .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover":
        {
          background: "main.800",
        },

      ".react-datepicker__close-icon::after": {
        backgroundColor: "unset",
        borderRadius: "unset",
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "var(--light-gray)",
        height: "20px",
        width: "20px",
      },
      ".react-datepicker__close-icon::after:hover": {
        color: "var(--gray)",
      },
      ".rcp": {
        w: "100% !important",
        ".rcp-saturation": {
          borderRadius: 0,
        },
        ".rcp-body": {
          paddingBottom: 0,
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "10px",
          gap: "10px",
        },
        ".rcp-fields": {
          width: "calc(100% + 20px)",
          marginLeft: "-10px",
          marginRight: "-10px",
        },
        ".rcp-fields-element-label": {
          fontWeight: "normal",
          color: "black",
          fontSize: ".875rem",
        },
        input: {
          boxShadow: "none !important",
          borderWidth: "1px",
          borderColor: "light.300",
          outline: "none",
          fontSize: ".875rem",
          padding: ".5rem .75rem",
          borderRadius: ".375rem",
          lineHeight: "1.15",
          color: "text.900",
          transition: "border-color .2s cubic-bezier(.4,0,.2,1)",
          fontWeight: "normal",
          _focus: {
            borderColor: "main.600",
          },
        },
      },
      ".quill-editor": {
        borderRadius: ".375rem",
        borderWidth: 1,
        borderColor: "gray.200",
        transition: "border-color .2s cubic-bezier(.4,0,.2,1)",
      },
      ".ql-toolbar.ql-snow": {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: "gray.200",
        transition: "border-color .2s cubic-bezier(.4,0,.2,1)",
        button: {
          width: "auto",
          padding: "3px",
        },
        "button.ql-active": {
          borderRadius: ".375rem",
          background: "gray.200",
        },
      },
      ".quill-editor.is-source-editing": {
        ".ql-toolbar": {
          "button:not(.ql-source-edit), .ql-picker": {
            color: "gray.300",
            pointerEvents: "none",
          },
        },
      },
      ".quill": {
        border: 0,
        ".ql-toolbar.ql-snow": {
          border: 0,
          ".ql-picker.ql-expanded": {
            ".ql-picker-label": {
              borderColor: "transparent",
            },
            ".ql-picker-options": {
              mt: ".25rem",
              boxShadow: "0 3px 10px rgb(0, 0, 0, 0.09)",
              borderWidth: 0,
              borderRadius: ".375rem",
              p: ".5rem",
              ".ql-picker-item": {
                padding: ".5rem",
                borderRadius: ".375rem",
                color: "text.900",
                transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
                transitionDuration: ".3s",
                transitionProperty:
                  "background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter",
                _hover: { bg: "light.100" },
                _active: { bg: "light.100" },
                _focus: { bg: "light.100" },
              },
            },
          },
        },
        ".ql-tooltip": {
          boxShadow: "0 3px 10px rgb(0, 0, 0, 0.09)",
          borderWidth: 0,
          borderRadius: ".375rem",
          p: ".5rem",
          zIndex: 1,
          "input[type=text]": {
            fontSize: ".875rem",
            padding: ".475rem .75rem",
            borderRadius: ".375rem",
            lineHeight: "1.15",
            border: "1px solid",
            borderColor: "light.300",
            transition: "border-color .2s cubic-bezier(.4,0,.2,1)",
            outline: "none",
            height: "auto",
            outlineOffset: 0,
            _focus: {
              borderColor: "main.600",
            },
          },
          _before: {
            fontSize: ".875rem",
            mr: 2,
          },
          "a.ql-action": {
            bg: "main.800",
            color: "light.10",
            ml: 2,
            _hover: {
              bg: "main.600",
            },
            _active: {
              bg: "main.600",
            },
            _focus: {
              bg: "main.600",
            },
            _after: {
              pr: 0,
              ml: 0,
              borderRight: 0,
            },
          },
          "a.ql-remove": {
            ml: 2,
            bg: "light.10",
            color: "text.700",
            boxShadow: "0 3px 20px rgba(0,0,0,0.04)",
            _hover: {
              bg: "light.200",
            },
            _focus: {
              boxShadow: "0 3px 20px rgba(0,0,0,0.04)",
            },
            _active: {
              bg: "light.200",
            },
            _before: {
              ml: 0,
            },
          },
          "a.ql-action, a.ql-remove": {
            fontWeight: "500",
            borderRadius: ".375rem",
            boxShadow:
              "0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgba(0,0,0,0.05)",
            transitionProperty:
              "background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter",
            transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
            transitionDuration: ".2s",
            fontSize: "0.875rem",
            lineHeight: 1.2,
            height: "2rem",
            px: "12px",
            verticalAlign: "middle",
            d: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
        ".ql-container.ql-snow": {
          border: 0,
        },
        ".large": {
          width: "100%",
        },
        ".medium": {
          width: "60%",
        },
        ".small": {
          width: "30%",
        },
        ".ql-cloudlink": {
          display: "inline",
        },
        ".ql-editor": {
          a: {
            color: "main.800",
            fontWeight: "bold",
            _hover: {
              color: "text.900",
            },
          },
          blockquote: {
            borderLeftWidth: 5,
            borderColor: "main.800",
            paddingLeft: "30px",
          },
        },
        ".ql-align-center": {
          "img, iframe": {
            marginLeft: "auto",
            marginRight: "auto",
          },
        },
        "div.ql-audio.ql-align-center, div.ql-video.ql-align-center": {
          marginLeft: "auto",
          marginRight: "auto",
        },
        ".ql-align-right": {
          "img, iframe": {
            display: "inline-block",
          },
        },
        "div.ql-audio.ql-align-right, div.ql-video.ql-align-right": {
          marginLeft: "auto",
        },
        ".ql-audio, .ql-video": {
          display: "flex",
          width: "100%",
          height: 250,
          borderWidth: 1,
          borderStyle: "dotted",
          borderColor: "gray.500",
          borderRadius: ".375rem",
          background: "gray.200",
          "> span": {
            display: "flex",
            width: "100%",
          },
          div: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            "> a": {
              width: "100%",
              textAlign: "center",
            },
          },
        },
        ".ql-audio[data-size=Medium], .ql-video[data-size=Medium]": {
          width: "60%",
        },
        ".ql-audio[data-size=Small], .ql-video[data-size=Small]": {
          width: "30%",
        },
      },
      "div.ql-tweet": {
        width: "calc(100% - 50px)",
        ".twitter-tweet": {
          display: "inline-flex !important",
        },
      },
      ".quill-editor:focus-within": {
        borderColor: "main.800",
        ".ql-toolbar.ql-snow": {
          borderColor: "main.800",
        },
      },
      ".quill-editor.is-invalid": {
        borderColor: "red.500",
        ".ql-toolbar.ql-snow": {
          borderColor: "red.500",
        },
      },
      ".quill-editor .quill-container": {
        position: "relative",
        ".raw-editor": {
          position: "absolute",
          width: "calc(100% - 4px)",
          inset: "2px",
          padding: "8px 13px",
          fontSize: "13px",
          fontFamily: "'Courier New', monospace",
          resize: "none",
          outline: "none",
        },
      },
      ".ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected":
        {
          color: "main.800",
        },
      ".ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter":
        {
          stroke: "main.800",
        },
      ".leaflet-container": {
        zIndex: 1,
        a: {
          color: "main.800",
          transitionProperty: "color",
          transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
          transitionDuration: ".2s",
          _hover: {
            textDecoration: "none",
            color: "main.500",
          },
        },
      },
      ".leaflet-touch .leaflet-bar": {
        border: 0,
        a: {
          borderRadius: ".375rem !important",
          bg: "white",
          color: "text.700",
          boxShadow: "0 3px 20px rgba(0,0,0,0.04)",
          transitionProperty: "background-color",
          transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
          transitionDuration: ".2s",
          fontWeight: "normal",
          fontSize: "26px",
          _first: {
            borderBottom: 0,
            mb: ".5rem",
          },
          _hover: {
            bg: "light.200",
          },
          _focus: {
            boxShadow: "0 3px 20px rgba(0,0,0,0.04)",
          },
          _active: {
            bg: "light.200",
          },
        },
      },
      ".rbc-calendar": {
        ".rbc-toolbar": {
          mb: "1.5em",
        },
        ".rbc-btn-group": {
          button: {
            borderColor: "light.50",
            minW: "5rem",
            p: ".4em .65em",
            cursor: "pointer",
            transitionProperty: "all",
            transitionDuration: ".2s",
            transitionTimingFunction: "cubic-bezier(.4,0,1,1)",
            lineHeight: 1.5,
            fontSize: "14px",
            color: "text.500",
            boxShadow:
              "0 0 transparent,0 0 transparent,0 1px 2px 0 rgba(0,0,0,0.05)",
            _hover: {
              backgroundColor: "white",
            },
            _first: {
              borderTopLeftRadius: ".375rem",
              borderBottomLeftRadius: ".375rem",
            },
            _last: {
              borderTopRightRadius: ".375rem",
              borderBottomRightRadius: ".375rem",
            },
            _focus: {
              boxShadow: "none",
              backgroundColor: "white",
            },
            "&.rbc-active, &:active": {
              borderColor: "main.800",
              boxShadow: "none",
              bg: "main.800",
              color: "light.10",
              _hover: {
                backgroundColor: "main.600",
              },
              _focus: {
                backgroundColor: "main.800",
              },
            },
          },
        },
        ".rbc-toolbar-label": {
          fontSize: "1.125rem",
          lineHeight: "1.75rem",
          fontWeight: 500,
        },
        ".rbc-month-view, .rbc-time-view": {
          borderRadius: ".375rem",
        },
        ".rbc-time-header-cell": {
          minH: "49px",
        },
        ".rbc-header": {
          p: ".75rem 1.25rem",
          fontSize: "1em",
          fontWeight: 500,
          "span, a": {
            display: "inline-block",
            p: "2px 4px",
          },
        },
        ".rbc-date-cell": {
          color: "text.900",
        },
        ".rbc-off-range": {
          opacity: 0.3,
        },
        ".rbc-day-bg, .rbc-header, .rbc-month-row, .rbc-month-view, .rbc-time-view, .rbc-timeslot-group, .rbc-events-container, .rbc-time-header.rbc-overflowing, .rbc-time-header-content, .rbc-time-content, .rbc-agenda-view table.rbc-agenda-table, .rbc-agenda-view td, .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr":
          {
            borderColor: "light.50",
          },
        ".rbc-off-range-bg": {
          backgroundColor: "light.20",
        },
        ".rbc-date-cell > a": {
          display: "inline-block",
          p: "7px 4px",
        },
        ".rbc-today": {
          backgroundColor: "light.50",
        },
        ".rbc-date-cell.rbc-now": {
          fontWeight: "normal",
        },
        ".rbc-row-segment": {
          px: "2px",
        },
        ".rbc-event, .rbc-day-slot .rbc-background-event": {
          backgroundColor: "main.800",
          borderRadius: "0.375rem",
          mt: "1px",
          ".rbc-event-content": {
            padding: "0.25rem 0.5rem",
            fontSize: ".75rem",
            lineHeight: "1rem",
            overflow: "visible",
          },
          _focus: {
            outline: "none",
          },
        },
        ".rbc-current-time-indicator": {
          backgroundColor: "main.800",
        },
        ".rbc-agenda-view table.rbc-agenda-table thead > tr > th": {
          backgroundColor: "light.50",
          borderBottom: 0,
          p: ".75rem 1.25rem",
        },
        ".rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td": {
          borderColor: "transparent",
        },
        ".rbc-agenda-view table.rbc-agenda-table tbody > tr > td": {
          p: "8px 14px",
        },
        ".rbc-agenda-view table.rbc-agenda-table tbody > tr": {
          "&:hover": {
            backgroundColor: "light.300",
          },
        },
        ".rbc-agenda-event-cell::before": {
          d: "inline-block",
          content: '""',
          mr: "14px",
          w: "10px",
          h: "10px",
          borderRadius: "50%",
          backgroundColor: "main.800",
        },
      },
      ".dashboard": {
        ".rbc-calendar": {
          ".rbc-header": {
            p: ".25rem 0",
            span: {
              px: 0,
            },
          },
          ".rbc-date-cell": {
            textAlign: "center",
            pr: 0,
            "> a": {
              padding: "0.25rem 0.75rem",
            },
          },
          ".rbc-day-bg, .rbc-header, .rbc-month-row, .rbc-month-view, .rbc-time-view, .rbc-timeslot-group, .rbc-events-container, .rbc-time-header.rbc-overflowing, .rbc-time-header-content, .rbc-time-content, .rbc-agenda-view table.rbc-agenda-table, .rbc-agenda-view td, .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr":
            {
              borderColor: "light.50",
            },
          ".rbc-event": {
            px: 0,
          },
          ".rbc-off-range-bg": {
            backgroundColor: "transparent",
          },
          ".rbc-today": {
            backgroundColor: "light.50",
          },
          ".rbc-current a": {
            backgroundColor: "light.50",
            borderRadius: ".25rem",
            p: ".25rem .75rem",
          },
          ".rbc-toolbar": {
            position: "relative",
          },
          ".rbc-btn-group": {
            button: {
              border: 0,
              boxShadow: "none",
              position: "absolute",
              top: 0,
              fontSize: 0,
              color: "transparent",
              opacity: 0.5,
              minW: 0,
              _before: {
                content: '""',
                w: "20px",
                h: "20px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              },
              "&:first-of-type": {
                display: "none",
              },
              "&:nth-of-type(2)": {
                left: 0,
                _before: {
                  display: "block",
                  backgroundImage: `url('${publicRuntimeConfig.basePath}/chevron-left.svg')`,
                },
              },
              "&:last-of-type": {
                right: 0,
                _before: {
                  display: "block",
                  backgroundImage: `url('${publicRuntimeConfig.basePath}/chevron-right.svg')`,
                },
              },
              "&.rbc-active, &:active": {
                bg: "white",
                opacity: 1,
                _hover: {
                  backgroundColor: "white",
                },
                _focus: {
                  backgroundColor: "white",
                },
              },
            },
          },
        },
      },
    },
  },

  components: {
    Breadcrumb: {
      parts: ["link", "separator"],
      baseStyle: {
        link: {
          color: "main.800",
          _hover: {
            textDecoration: "none",
          },
        },
      },
    },

    Menu: {
      parts: ["item", "command", "list", "button", "groupTitle", "divider"],
      baseStyle: {
        item: {
          padding: ".5rem",
          borderRadius: ".375rem",
          color: "text.900",
          transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
          transitionDuration: ".3s",
          transitionProperty:
            "background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter",
          _hover: { bg: "light.100" },
          _active: { bg: "light.100" },
          _focus: { bg: "light.100" },
        },
        list: {
          mt: ".25rem",
          boxShadow: "0 3px 10px rgb(0, 0, 0, 0.09)",
          borderWidth: 0,
          borderRadius: ".375rem",
          p: ".5rem",
        },
        divider: {
          my: ".5rem",
          borderColor: "light.200",
        },
      },
    },

    Button: {
      baseStyle: {
        fontWeight: "500",
        borderRadius: ".375rem",
        boxShadow:
          "0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgba(0,0,0,0.05)",
        transitionProperty:
          "background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter",
        transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
        transitionDuration: ".2s",
        fontSize: "14px",
        _disabled: {
          pointerEvents: "none",
          cursor: "default",
        },
        flex: { base: 1, md: "none" },
        w: { base: "100%", md: "auto" },
      },
      sizes: {
        md: {
          p: ".5rem .75rem",
          lineHeight: 1.15,
        },
        xl: {
          p: ".75rem 1rem",
          minW: "8rem",
          lineHeight: "1.25rem",
        },
      },
      variants: {
        solid: {
          bg: "main.800",
          color: "light.10",
          _hover: {
            bg: "main.600",
          },
          _groupHover: {
            bg: "main.600",
          },
          _focus: {
            boxShadow: "none",
            bg: "main.600",
          },
          _active: {
            bg: "main.600",
          },
          _groupActive: {
            bg: "main.600",
          },
        },
        ghost: {
          bg: "white",
          color: "text.700",
          boxShadow: "0 3px 20px rgba(0,0,0,0.04)",
          _hover: {
            bg: "light.200",
          },
          _groupHover: {
            bg: "light.200",
          },
          _focus: {
            boxShadow: "0 3px 20px rgba(0,0,0,0.04)",
          },
          _active: {
            bg: "light.200",
          },
          _groupActive: {
            bg: "light.200",
          },
        },
        red: {
          bg: "red.500",
          color: "white",
          _hover: {
            bg: "red.400",
          },
          _focus: {
            boxShadow: "none",
            bg: "red.400",
          },
          _active: {
            bg: "red.400",
          },
        },
        green: {
          bg: "green.500",
          color: "white",
          _hover: {
            bg: "green.400",
          },
          _focus: {
            boxShadow: "none",
            bg: "green.400",
          },
          _active: {
            bg: "green.400",
          },
        },
        outline: {
          color: "text.700",
          borderColor: "light.300",
          _hover: {
            bg: "light.100",
          },
          _focus: {
            boxShadow:
              "0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgba(0,0,0,0.05)",
          },
          _active: {
            bg: "light.100",
          },
        },
        dark: {
          color: "white",
          borderColor: "white",
          borderWidth: 1,
          _hover: {
            bg: "rgba(255,255,255,0.1)",
          },
          _focus: {
            boxShadow: "none",
          },
          _active: {
            bg: "rgba(255,255,255,0.2)",
          },
        },
      },
    },

    Heading: {
      baseStyle: {
        lineHeight: "1.2",
      },
      sizes: {
        sm: {
          fontWeight: "600",
          mb: "15px",
        },
        md: {
          fontWeight: "500",
          fontSize: "1rem",
        },
        lg: {
          fontWeight: "500",
          fontSize: "1.125rem",
          mb: "1.5rem",
        },
        xl: {
          fontWeight: "700",
          fontSize: "1.875rem",
          mb: "2rem",
        },
      },
    },

    Alert: {
      parts: ["container", "title", "description", "icon"],
      baseStyle: {
        container: {
          borderRadius: ".375rem",
        },
      },
      defaultProps: {
        variant: "solid",
      },
    },

    Input: {
      parts: ["field", "addon"],
      sizes: {
        sm: {
          field: {
            fontSize: ".75rem",
            padding: "0.375rem .5rem",
            borderRadius: ".375rem",
            lineHeight: "1rem",
          },
        },
        md: {
          addon: {
            fontSize: ".875rem",
          },
          field: {
            fontSize: ".875rem",
            padding: ".5rem .75rem",
            borderRadius: ".375rem",
            lineHeight: "1.15",
          },
        },
        lg: {
          field: {
            fontSize: ".875rem",
            padding: ".75rem 1rem",
            borderRadius: ".375rem",
            lineHeight: "1.15",
            boxShadow: "0 3px 5px rgb(0,0,0,0.03)",
          },
        },
        xl: {
          field: {
            fontSize: ".875rem",
            p: ".75rem",
            borderRadius: ".375rem",
            lineHeight: "1.25rem",
            boxShadow: "0 3px 5px rgb(0,0,0,0.03)",
            border: "0",
          },
        },
      },
      variants: {
        unstyled: {
          addon: {
            borderColor: "light.300",
            backgroundColor: "light.300",
            p: 2,
          },
          field: {
            backgroundColor: "#FFFFFF",
            borderWidth: "1px",
            borderColor: "light.300",
            _focus: {
              borderColor: "main.600",
            },
            _disabled: {
              bg: "gray.100",
            },
            _readOnly: {
              cursor: "default",
              pointerEvents: "none",
            },
            _invalid: {
              borderColor: "red.500",
            },
          },
        },
      },
      defaultProps: {
        variant: "unstyled",
      },
    },

    Textarea: {
      sizes: {
        md: {
          fontSize: ".875rem",
          padding: ".5rem .75rem",
          borderRadius: ".375rem",
          lineHeight: "1.15",
        },
      },
      variants: {
        unstyled: {
          backgroundColor: "#FFFFFF",
          borderWidth: "1px",
          borderColor: "light.300",
          resize: "none",
          _focus: {
            borderColor: "main.600",
          },
          _disabled: {
            bg: "gray.100",
          },
          _readOnly: {
            cursor: "default",
            pointerEvents: "none",
          },
          _invalid: {
            borderColor: "red.500",
          },
        },
      },
      defaultProps: {
        variant: "unstyled",
      },
    },

    Select: {
      parts: ["field", "icon"],
      sizes: {
        md: {
          field: {
            fontSize: "13px",
            padding: "10px",
            borderRadius: "0px",
          },
        },
      },
      variants: {
        unstyled: {
          field: {
            borderWidth: "1px",
            borderColor: "gray.200",
            _focus: {
              borderColor: "main.800",
            },
            _disabled: {
              bg: "gray.100",
            },
            _readOnly: {
              cursor: "default",
              pointerEvents: "none",
            },
            _invalid: {
              borderColor: "red.500",
            },
          },
        },
      },
      defaultProps: {
        variant: "unstyled",
      },
    },

    FormLabel: {
      baseStyle: {
        display: "inline-block",
        fontWeight: "normal",
        color: "black",
        mr: 0,
        mb: 0,
        pb: ".5rem",
        fontSize: ".875rem",
      },
    },

    FormError: {
      parts: ["text", "icon"],
      baseStyle: {
        text: {
          marginTop: "5px",
          fontSize: "12px",
        },
      },
    },

    CloseButton: {
      baseStyle: {
        color: "gray.500",
        _focus: {
          boxShadow: "none",
          color: "black",
        },
        _hover: {
          bg: "transparent",
          color: "black",
        },
        _active: {
          bg: "transparent",
          color: "black",
        },
      },
    },

    Badge: {
      baseStyle: {
        px: "7.5px",
        py: "2.5px",
        borderRadius: ".375rem",
        fontWeight: "normal",
        fontSize: "11px",
      },
      defaultProps: {
        variant: "solid",
      },
      variants: {
        gray: {
          bg: "gray.100",
        },
        solid: {
          bg: "main.800",
        },
        red: {
          bg: "red.500",
          color: "white",
        },
        green: {
          bg: "green.500",
          color: "white",
        },
      },
    },

    Tag: {
      parts: ["container", "label", "closeButton"],
      baseStyle: {
        container: {
          fontWeight: "normal",
          textTransform: "uppercase",
        },
      },
      sizes: {
        md: {
          container: {
            px: "7.5px",
            py: "2.5px",
            borderRadius: ".375rem",
            fontSize: "11px",
          },
        },
      },
      variants: {
        solid: {
          container: {
            bg: "main.800",
          },
        },
        blue: {
          container: {
            bg: "main.900",
            color: "light.10",
          },
        },
        green: {
          container: {
            bg: "green.500",
            color: "white",
          },
        },
        red: {
          container: {
            bg: "red.500",
            color: "white",
          },
        },
        gray: {
          container: {
            bg: "gray.100",
            color: "text.900",
          },
        },
      },
    },

    Avatar: {
      parts: ["container", "excessLabel", "badge", "label"],
      baseStyle: {
        container: {
          transitionProperty: "background-color",
          transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
          transitionDuration: ".2s",
        },
      },
      sizes: {
        sm: {
          container: {
            w: "30px",
            h: "30px",
          },
        },
      },
      defaultProps: {
        size: "sm",
      },
    },

    Tooltip: {
      baseStyle: {
        p: ".375rem .75rem",
        borderRadius: ".375rem",
        fontSize: "14px",
        lineHeight: 1.4,
        bg: "#333",
        boxShadow: "none",
        color: "white",
        fontWeight: "normal",
      },
    },

    Switch: {
      parts: ["track", "thumb"],
      baseStyle: {
        track: {
          bg: "light.300",
          _checked: {
            bg: "main.800",
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },

    Link: {
      baseStyle: {
        color: "main.800",
        fontWeight: "500",
        _hover: {
          color: "text.900",
          textDecoration: "none",
        },
        _focus: {
          boxShadow: "none",
        },
      },
    },

    Slider: {
      parts: ["container", "thumb", "track", "filledTrack"],
      baseStyle: {
        track: {
          borderRadius: 0,
        },
        thumb: {
          boxShadow: "none",
          borderColor: "gray.200",
          _focus: {
            boxShadow: "none",
          },
        },
        filledTrack: {
          bg: "main.100",
        },
      },
      defaultProps: {
        size: "lg",
      },
    },

    Tabs: {
      parts: ["root", "tablist", "tab", "tabpanel", "indicator"],
      baseStyle: {
        tabpanel: {
          p: "1.25rem",
        },
        tablist: {
          flexWrap: { base: "nowrap", md: "wrap" },
          overflow: { base: "auto", md: "hidden" },
          bg: "light.300",
          borderTopRadius: ".375rem",
        },
        tab: {
          whiteSpace: { base: "nowrap", md: "normal" },
        },
      },
      variants: {
        solid: {
          tab: {
            fontWeight: "500",
            py: "1rem",
            minWidth: "5rem",
            fontSize: ".875rem",
            color: "text.500",
            flexShrink: 0,
            _focus: {
              boxShadow: "none",
            },
            _selected: {
              bg: "#FFFFFF",
              color: "main.800",
            },
          },
        },
        buttons: {
          tablist: {
            p: ".5rem",
            boxShadow: "0 3px 20px rgba(0,0,0,0.04)",
            bg: "white",
            mb: "1.25rem",
            borderBottomRadius: ".375rem",
          },
          tab: {
            justifyContent: "flex-start",
            borderRadius: ".375rem",
            px: "20px",
            py: 2,
            mr: 2,
            cursor: "pointer",
            transitionProperty: "all",
            transitionDuration: ".3s",
            transitionTimingFunction: "cubic-bezier(.4,0,1,1)",
            lineHeight: "1.25rem",
            fontSize: ".875rem",
            _last: {
              mr: 0,
            },
            _hover: {
              bg: "light.100",
            },
            _focus: {
              boxShadow: "none",
            },
            _selected: {
              bg: "main.800",
              color: "light.10",
            },
          },
          tabpanel: {
            p: 0,
          },
        },
        sidebar: {
          root: {
            d: "flex",
          },
          tablist: {
            d: "block",
            w: "23%",
            mr: 6,
            p: "1.25rem",
            boxShadow: "0 3px 20px rgba(0,0,0,0.04)",
            bg: "white",
            flexShrink: 0,
          },
          tab: {
            w: "100%",
            justifyContent: "flex-start",
            borderRadius: ".375rem",
            px: ".75rem",
            py: 2,
            mb: 2,
            cursor: "pointer",
            transitionProperty: "all",
            transitionDuration: ".3s",
            transitionTimingFunction: "cubic-bezier(.4,0,1,1)",
            lineHeight: "1.25rem",
            fontSize: ".875rem",
            _last: {
              mb: 0,
            },
            _hover: {
              bg: "light.100",
            },
            _focus: {
              boxShadow: "none",
            },
            _selected: {
              bg: "main.800",
              color: "light.10",
              fontWeight: "500",
            },
          },
          tabpanel: {
            p: 0,
          },
        },
      },
      defaultProps: {
        variant: "solid",
      },
    },

    Checkbox: {
      parts: ["container", "control", "label", "icon"],
      baseStyle: {
        control: {
          borderRadius: ".2em",
          transition: "all .25s ease-in-out",
          borderWidth: "1px",
          borderColor: "light.300",
          _focus: {
            borderColor: "main.800",
            boxShadow: "none",
          },
          _disabled: {
            bg: "gray.100",
            borderColor: "light.300",
            cursor: "default",
          },
          _checked: {
            bg: "main.800",
            borderColor: "main.800",
            _hover: {
              bg: "main.600",
              borderColor: "main.600",
            },
            _focus: {
              borderColor: "main.800",
            },
          },
        },
        container: {
          maxWidth: "100%",
        },
        label: {
          paddingTop: "2px",
          lineHeight: "1.4",
          ml: "10px",
          maxWidth: "calc(100% - 28px)",
          _disabled: {
            cursor: "default",
          },
        },
      },
      sizes: {
        sm: {
          control: {
            w: "18px",
            h: "18px",
          },
        },
        md: {
          control: {
            w: "24px",
            h: "24px",
          },
          label: {
            fontSize: "14px",
          },
        },
        lg: {
          control: {
            w: "24px",
            h: "24px",
          },
        },
      },
    },

    Radio: {
      parts: ["container", "control", "label"],
      baseStyle: {
        control: {
          borderWidth: "1px",
          borderColor: "light.300",
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
          _hover: {
            bg: "white",
            borderColor: "light.300",
          },
          _checked: {
            color: "main.800",
            borderColor: "light.300",
            bg: "white",
            _hover: {
              bg: "white",
              borderColor: "light.300",
            },
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },

    Accordion: {
      parts: ["container", "button", "panel", "icon"],
      baseStyle: {
        container: {
          borderColor: "light.200",
        },
        button: {
          p: 3,
          fontWeight: "500",
          fontSize: "1.125rem",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: "transparent",
          },
          _expanded: {
            color: "main.800",
          },
        },
        panel: {
          px: 0,
          pt: 0,
          pb: 3,
        },
      },
    },

    Popover: {
      parts: ["popper", "content", "header", "body", "footer", "arrow"],
      baseStyle: {
        popper: {
          zIndex: 999,
        },
        content: {
          boxShadow: "0 3px 10px rgb(0, 0, 0, 0.09)",
          borderWidth: 0,
          borderRadius: ".375rem",
          _focus: {
            boxShadow: "0 3px 10px rgb(0, 0, 0, 0.09)",
          },
        },
        header: {
          fontWeight: 500,
          fontSize: "15px",
          borderColor: "light.50",
          textAlign: "left",
        },
      },
    },

    Stat: {
      parts: ["container", "label", "helpText", "number", "icon"],
      baseStyle: {
        label: {
          fontWeight: "500",
          mb: 2,
        },
        number: {
          fontWeight: "500",
        },
      },
    },

    Progress: {
      parts: ["label", "track"],
      baseStyle: {
        track: {
          borderRadius: ".375rem",
        },
      },
    },

    Modal: {
      parts: [
        "dialogContainer",
        "dialog",
        "header",
        "closeButton",
        "body",
        "footer",
      ],
      baseStyle: {
        dialog: {
          borderRadius: ".375rem",
          my: { base: 0, md: "3.75rem" },
          color: "black",
        },
        header: {
          fontSize: "1rem",
          fontWeight: "500",
          borderBottomColor: "light.50",
          borderBottomWidth: "1px",
          px: { base: 4, md: 6 },
        },
        body: {
          px: { base: 4, md: 6 },
        },
      },
    },
  },
};

// @ts-ignore
export default extendTheme(overrides);
