import { useRouter } from "next/router";
import React from "react";

const needSubscriptionUrl = [
  /^\/press/,
  /^\/calendar/,
  /^\/news/,
  /^\/offices/,
  /^\/newslatter/,
  /^\/users/,
  /^\/events/,
  /^\/accreditation/,
  /^\/cloud/,
  /^\/calendar/,
  /^\/website/,
  /^\/users/,
];

const useNeedsSubscription = (): {
  isLoading?: boolean;
  needsSubscription?: boolean;
} => {
  const router = useRouter();
  const pathname = router.pathname;
  const [needs, setNeeds] = React.useState(false);

  React.useEffect(() => {
    if (pathname) {
      needSubscriptionUrl?.forEach((regex) => {
        if (regex.test(pathname)) {
          setNeeds(true);
          return;
        }
      });
    }
  }, [pathname]);

  const isLoading = !pathname;

  return {
    isLoading,
    needsSubscription: needs,
  };
};

export default useNeedsSubscription;
