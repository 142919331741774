export default {
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Roboto', sans-serif",
  },
  colors: {
    main: {
      900: "#1a389f",
      800: "#1c3faa",
      700: "#274ab7",
      600: "#2e51bb",
      500: "#b5c8ff",
    },
    light: {
      300: "#e2e8f0",
      200: "#dee7ef",
      100: "#f1f5f8",
      50: "#edf2f7",
    },
    text: {
      900: "#2d3748",
      800: "#365a74",
      700: "#4a5568",
      500: "#718096",
      400: "#a0aec0",
      100: "#c7d2ff",
    },
    red: {
      500: "#d32929",
      400: "#e34545",
    },
    green: {
      500: "#91c714",
      400: "#9ccc2d",
    },
    orange: {
      500: "#fbc500",
    },
    blue: {
      500: "#1c3faa",
    },
  },
  styles: {
    global: {
      body: {
        bg: "orange.500",
        fontSize: ".875rem",
        lineHeight: "1.25rem",
        color: "text.900",
        overflowX: "hidden",
      },
      img: {
        maxWidth: "100%",
      },
      a: {
        transition: "all .2s cubic-bezier(.4,0,.2,1)",
        _hover: { textDecoration: "none" },
        img: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
        span: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
        svg: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
      },
    },
  },
};
