import React, { useMemo } from "react";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { LanguageShort } from "@/types/common";
import { Image } from "@/components/common/Image";
import { updateProfile } from "@/components/withAuthentication";
import { mutateSession, useUser } from "@/hooks/useUser";
import { languagesShort, getFlagShort } from "@/utils/language";

type Props = {
  isWebsite?: boolean;
  isPanel?: boolean;
  isWebsiteWithoutSubdomain?: boolean;
};

export default function LanguageSwitcher({
  isPanel = false,
  isWebsite = false,
  isWebsiteWithoutSubdomain = false,
  ...rest
}: Props): React.ReactElement {
  const [cookie, setCookie] = useCookies(["NEXT_LOCALE"]);
  const [session] =
    isPanel || isWebsite || isWebsiteWithoutSubdomain ? useUser() : [];
  const router = useRouter();
  const { locale, asPath } = router;

  const languages = useMemo<{ [x in LanguageShort]: { icon: string } }>(
    () =>
      languagesShort.reduce(
        (acc, curr) => (
          (acc[curr] = {
            icon: getFlagShort(curr),
          }),
          acc
        ),
        {}
      ),
    [languagesShort]
  );

  const currentLanguage = languages[locale];

  const switchLanguage = (locale) => async () => {
    if (cookie.NEXT_LOCALE !== locale && navigator.cookieEnabled) {
      setCookie("NEXT_LOCALE", locale, { path: "/", domain: ".accred.eu" });
    }

    if (session?.user?.id) {
      await updateProfile({ locale });

      await mutateSession();
    }
    if (isWebsite) router.reload(window.location.pathname);
    else router.push(asPath, asPath, { locale });
  };

  return (
    <Menu placement="bottom-end">
      <MenuButton
        mr={isPanel && { base: ".75rem", md: "1.5rem" }}
        role="group"
        {...rest}
      >
        <IconButton
          as="a"
          variant="ghost"
          aria-label=""
          icon={
            <Image
              priority
              dimensions={[20, 20]}
              objectFit="contain"
              src={`/flags/${currentLanguage?.icon}`}
              alt="Language icon"
            />
          }
          size="sm"
          px=".25rem"
        />
      </MenuButton>
      <MenuList w="52px" minWidth="1px" mr="-.75rem">
        {Object.entries(languages).map(([key, { icon }]) => (
          <MenuItem
            key={key}
            onClick={switchLanguage(key)}
            isDisabled={key === locale}
          >
            <Image
              dimensions={[20, 20]}
              layout="fill"
              objectFit="contain"
              src={`/flags/${icon}`}
              alt="Language icon"
            />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
