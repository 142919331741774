import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Flex, Button, Text, Box } from "@chakra-ui/react";

import { ImageAutoHeight } from "@/components/common/Image";
import LanguageSwitcher from "@/components/Layout/Header/LanguageSwitcher";
import { signOut } from "next-auth/client";

export const Maintenance = (): React.ReactElement => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const onRefresh = () => {
    router.reload(window.location.pathname);
  };

  return (
    <Box maxW="1536px" mx="auto">
      <Flex minH="100vh" justify="center" align="center">
        <Box mr="5rem" w="450px">
          <ImageAutoHeight src="/login.svg" layout="fill" alt="Main Image" />
        </Box>
        <Box color="white">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="6rem" fontWeight="500" lineHeight="1">
              {t("error.maintenance", "Maintenance")}
            </Text>
            <LanguageSwitcher />
          </Flex>
          <Text
            fontSize="1.875rem"
            mt="1.25rem"
            fontWeight="500"
            lineHeight="2.25rem"
          >
            {t("error.maintenanceHeader")}
          </Text>
          <Text fontSize="1.125rem" mt=".75rem" lineHeight="1.75rem">
            {t("error.maintenanceText")}
          </Text>
          <Button onClick={signOut} variant="dark" size="xl" mt="2.5rem">
            {t("users.logout")}
          </Button>
          <Button
            onClick={onRefresh}
            variant="dark"
            size="xl"
            mt="2.5rem"
            ml="0.5rem"
          >
            {t("refresh")}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default Maintenance;
