import React from "react";
import {
  Box,
  chakra,
  ThemingProps,
  useStyleConfig,
  useMultiStyleConfig,
  Skeleton,
} from "@chakra-ui/react";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { ReactElement } from "react";
import getConfig from "next/config";

import styled from "@emotion/styled";

const { publicRuntimeConfig } = getConfig();

// TODO review props when NextJs is updated so we don't have to defined it here
/**
 * ? Because NextJs typing is preventing auto-suggest for layout, width and height,
 * ? we declare the styles differently in this component and will manage the switch
 * ? to NextJs typings when calling NextJs `next/image` component
 */
type LayoutValue = "fixed" | "intrinsic" | "responsive" | undefined;

type LayoutAndSize =
  | {
      layout: "fill";
    }
  | {
      layout: LayoutValue;
      height: number;
      width: number;
    };

/**
 * Types for the Image component itself
 */
type ImageProps = Pick<
  NextImageProps,
  | "className"
  | "loading"
  | "objectFit"
  | "objectPosition"
  | "priority"
  | "quality"
  | "src"
  | "unoptimized"
  | "width"
  | "height"
> &
  Pick<Required<NextImageProps>, "alt"> &
  Pick<ThemingProps, "variant"> & {
    dimensions?: [number, number];
    layout?: "fill" | LayoutValue;
  };

/**
 * Wraps NextJs `next/image` component in Chakra's factory function
 * This is what will allow to use the theme and the styling properties on the component
 */
const ImageWithChakra = chakra(
  ({
    className,
    dimensions = [0, 0],
    layout = "fill",
    loading,
    objectFit,
    objectPosition,
    priority,
    quality,
    src,
    unoptimized,
    ...nextjsInternals
  }: ImageProps): ReactElement => {
    if (!src) return null;
    const [width, height] = dimensions;
    const layoutAndSize: LayoutAndSize =
      height > 0 || width > 0
        ? {
            height,
            layout: layout === "fill" ? "intrinsic" : layout,
            width,
          }
        : {
            layout: "fill",
          };

    return (
      <NextImage
        className={className}
        loading={loading}
        objectFit={objectFit}
        objectPosition={objectPosition}
        priority={priority}
        quality={quality}
        src={`${publicRuntimeConfig.basePath || ""}${src}`}
        unoptimized={unoptimized}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...layoutAndSize}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...nextjsInternals}
      />
    );
  }
);

const useImageLoaded = (src) => {
  const [loaded, setLoaded] = React.useState(false);
  const ref = React.useRef(null);
  const [hasRendered, setHasRendered] = React.useState(false);
  const [imageUnavailable, setImageUnavailable] = React.useState(!src);

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setImageUnavailable(true);
  };

  React.useEffect(() => {
    if (src) setImageUnavailable(false);
    if (ref.current && hasRendered) {
      ref.current.src = src || "";
    }
  }, [src, hasRendered]);

  React.useEffect(() => {
    setLoaded(false);
  }, [src]);

  React.useEffect(() => {
    setHasRendered(true);
  }, []);

  return { ref, loaded, imageUnavailable, onLoad, onError };
};

export const ImageWithLoader = ({
  variant,
  ...props
}: ImageProps): ReactElement => {
  const styles = useStyleConfig("Image", { variant });

  const { src } = props;
  const { ref, loaded, imageUnavailable, onLoad, onError } =
    useImageLoaded(src);

  if (imageUnavailable || !src) return null;

  return (
    //TODO Find better solution to render this compononet
    <>
      {!loaded && <Skeleton w="100%" h="100%" />}
      <ImageWithChakra
        sx={styles}
        {...props}
        ref={ref}
        src={src}
        onLoad={onLoad}
        onError={onError}
      />
    </>
  );
};

export const Image = ({ variant, ...props }: ImageProps): ReactElement => {
  /**
   * ? This components serves as an interface to pass Chakra's styles
   * ? You can use the theme and/or styling properties (eg. backgroundColor='red.200')
   */
  const styles = useStyleConfig("Image", { variant });
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ImageWithChakra sx={styles} {...props} />;
};

const StyledImage = styled(NextImage)`
  object-fit: contain;
  width: 100% !important;
  height: auto !important;
  position: relative !important;
`;

const UnsetHeight = styled(Box)`
  display: flex;

  > div {
    width: 100%;
    height: auto !important;
    position: unset !important;
  }
`;

const UnsetWidth = styled(Box)`
  display: flex;
  height: 100%;

  > div {
    position: unset !important;
    height: 100%;

    img {
      width: auto !important;
    }
  }
`;

export const ImageAutoHeight = ({
  src,
  ...props
}: ImageProps): ReactElement => {
  if (!src) return null;

  return (
    <UnsetHeight>
      <StyledImage
        src={`${publicRuntimeConfig.basePath || ""}${src}`}
        {...props}
      />
    </UnsetHeight>
  );
};

export const ImageAutoWidth = React.forwardRef(
  ({ src, ...props }: ImageProps, ref): ReactElement => {
    if (!src) return null;

    return (
      <UnsetWidth>
        <StyledImage
          src={`${publicRuntimeConfig.basePath || ""}${src}`}
          {...props}
        />
      </UnsetWidth>
    );
  }
);

ImageAutoWidth.displayName = "ImageAutoWidth";
