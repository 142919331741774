import { ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react";
import * as Sentry from "@sentry/nextjs";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { CookieConsentProvider } from "@use-cookie-consent/react";
import axios from "axios";
import { Session } from "next-auth";
import { Provider, signOut } from "next-auth/client";
import { appWithTranslation, useTranslation } from "next-i18next";
import PlausibleProvider from "next-plausible";
import getConfig from "next/config";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { Middleware, SWRConfig, unstable_serialize } from "swr";

import NextNProgess from "@/components/common/NextNProgress";
import Maintenance from "@/components/Maintenance";
import { SettingsProvider } from "@/components/SettingsContext";
import { updatePanelTheme, updatePressTheme } from "@/utils/updateTheme";

import mainThemeOverrides from "@/utils/mainTheme";
import pressThemeOverrides from "@/utils/pressTheme";
import { overrides } from "@/utils/theme";

import { generateHMAC } from "services/hmac";
import nextI18nextConfig from "../next-i18next.config";
import Custom404Page from "./404";
import Custom500Page from "./500";

const { publicRuntimeConfig } = getConfig();

axios.defaults.baseURL = `${publicRuntimeConfig.basePath}/app-api`;
axios.defaults.withCredentials = true;
// axios.defaults.timeout = 10000;
axios.defaults.headers = {
  crossDomain: true,
  "Access-Control-Allow-Origin": "*",
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();

    if (
      error.response?.status === 500 ||
      (typeof error === "string" && error.startsWith("connect ECONNREFUSED"))
    )
      message = "ServerError";

    if (message === "LoggedOut" && typeof window !== "undefined") {
      signOut({ redirect: false });
    }

    return Promise.reject(message?.message || message);
  }
);

export const fetcher = (url, config) =>
  axios.get(url, config).then((res) => res.data);

const abortableMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    // for each key generate new AbortController
    const keyString = unstable_serialize(key);
    const abortController = useMemo(() => new AbortController(), [keyString]);

    // as soon as abortController is changed or component is unmounted, call abort
    useEffect(() => () => abortController.abort(), [abortController]);

    const fetcherExtended: typeof fetcher = (url, params) =>
      fetcher(url, { ...params, signal: abortController.signal });

    return useSWRNext(key, fetcherExtended, config);
  };
};

const settings = {
  menuCollapsed: null,
};

const panelTheme = extendTheme(overrides);
const mainTheme = extendTheme(mainThemeOverrides);
const pressTheme = extendTheme(pressThemeOverrides);

export enum Page {
  MAIN = "main",
  PANEL = "panel",
  PRESS = "press",
}

const pageTheme = {
  [Page.MAIN]: mainTheme,
  [Page.PANEL]: panelTheme,
  [Page.PRESS]: pressTheme,
};

const App = ({ Component, pageProps, err }) => {
  if (pageProps.error?.statusCode === 404)
    return (
      <ChakraProvider theme={panelTheme}>
        <Custom404Page />
      </ChakraProvider>
    );

  const pressColors = pageProps.pressColors ?? {};
  const [currentPage, setCurrentPage] = React.useState<Page>(
    pageProps.page ?? Page.PANEL
  );
  const [theme, setTheme] = React.useState(() => {
    const theme = pageTheme[currentPage] ?? panelTheme;
    if (currentPage === Page.PRESS) return updatePressTheme(pressColors, theme);
    return theme;
  });
  const [session, setSession] = React.useState<Session>(pageProps.session);
  const router = useRouter();
  const tawkMessengerRef = React.useRef();
  const colors = session?.colors;
  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (router?.pathname.startsWith("/main")) setCurrentPage(Page.MAIN);
    else if (router?.pathname.startsWith("/page")) setCurrentPage(Page.PRESS);
    else if (currentPage !== Page.PANEL) setCurrentPage(Page.PANEL);
  }, []);

  React.useEffect(() => {
    setTheme(pageTheme[currentPage] ?? panelTheme);
  }, [currentPage]);

  React.useEffect(() => {
    setCurrentPage(pageProps.page ?? Page.PANEL);
  }, [pageProps.page]);

  React.useEffect(() => {
    if (currentPage === Page.PRESS) {
      const updatedTheme = updatePressTheme(pressColors, pressTheme);
      setTheme(updatedTheme);
    } else if (colors && currentPage === Page.PANEL) {
      const updatedTheme = updatePanelTheme(colors, panelTheme);
      setTheme(updatedTheme);
    }
  }, [colors, currentPage]);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page, _) => page);
  const layout = getLayout(<Component err={err} {...pageProps} />, t);
  const page = session?.maintenance ? <Maintenance /> : layout;

  const fallback = (props) => (
    <ChakraProvider theme={panelTheme}>
      <Custom500Page {...props} />
    </ChakraProvider>
  );

  useEffect(() => {
    tawkMessengerRef?.current?.visitor({
      name: `${session?.user?.firstName} ${session?.user?.lastName}`,
      email: session?.user?.email,
      phone: session?.user?.phone,
      hash: generateHMAC(
        session?.user?.email,
        publicRuntimeConfig.TAWK_TO_API_KEY
      ),
    });
  }, [session?.user, tawkMessengerRef?.current]);

  const showTawkChat =
    session?.user &&
    router?.pathname !== "/signin" &&
    router?.pathname !== "/signup";

  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      <ChakraProvider theme={theme}>
        <CookieConsentProvider
          useCookieConsentHooksOptions={{
            consentCookieAttributes: { expires: 3650, domain: ".accred.eu" },
          }}
        >
          <SettingsProvider initialData={{ ...settings, setSession }}>
            <CSSReset />
            <NextNProgess
              options={{ easing: "ease", speed: 500 }}
              startPosition={0.3}
              stopDelayMs={200}
            />
            <SWRConfig
              value={{
                fetcher,
                // use: [abortableMiddleware],
                shouldRetryOnError: (error) => {
                  if (error === "LoggedOut") return false;

                  return true;
                },
              }}
            >
              <Provider
                session={session}
                options={{
                  basePath: `${publicRuntimeConfig.basePath}/api/auth`,
                  clientMaxAge: 30 * 60,
                  keepAlive: 5 * 60,
                }}
              >
                <PlausibleProvider domain="accred.eu">{page}</PlausibleProvider>
                {showTawkChat && (
                  <TawkMessengerReact
                    propertyId={publicRuntimeConfig.TAWK_TO_PROPERTY_ID}
                    widgetId={publicRuntimeConfig.TAWK_TO_WIDGET_ID}
                    ref={tawkMessengerRef}
                  />
                )}
              </Provider>
            </SWRConfig>
          </SettingsProvider>
        </CookieConsentProvider>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  );
};

export default appWithTranslation(App, nextI18nextConfig);
