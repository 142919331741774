export enum Language {
  POLISH = "polish",
  ENGLISH = "english",
  RUSSIAN = "russian",
}

export enum LanguageShort {
  PL = "pl",
  EN = "en",
  RU = "ru",
}

export type LanguageName = { [key in LanguageShort]: string };

export const Timezones = {
  "GMT-12:00": "-12",
  "GMT-11:00": "-11",
  "GMT-10:00": "-10",
  "GMT-09:00": "-9",
  "GMT-08:00": "-8",
  "GMT-07:00": "-7",
  "GMT-06:00": "-6",
  "GMT-05:00": "-5",
  "GMT-04:00": "-4",
  "GMT-03:00": "-3",
  "GMT-02:00": "-2",
  "GMT-01:00": "-1",
  "GMT+00:00": "0",
  "GMT+01:00": "+1",
  "GMT+02:00": "+2",
  "GMT+03:00": "+3",
  "GMT+04:00": "+4",
  "GMT+05:00": "+5",
  "GMT+06:00": "+6",
  "GMT+07:00": "+7",
  "GMT+08:00": "+8",
  "GMT+09:00": "+9",
  "GMT+10:00": "+10",
  "GMT+11:00": "+11",
  "GMT+12:00": "+12",
  "GMT+13:00": "+13",
  "GMT+14:00": "+14",
};
