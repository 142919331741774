import React from "react";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Flex, Button, Text, Box } from "@chakra-ui/react";

import { ImageAutoHeight } from "@/components/common/Image";
import LanguageSwitcher from "@/components/Layout/Header/LanguageSwitcher";

export const Custom500Page = ({ resetError }): React.ReactElement => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const onGoBack = async () => {
    await router.push("/");
    if (resetError) resetError();
  };

  const onRefresh = () => {
    if (resetError) resetError();
    router.reload(window.location.pathname);
  };

  return (
    <Box maxW="1536px" mx="auto">
      <Flex minH="100vh" justify="center" align="center">
        <Box mr="5rem" w="450px">
          <ImageAutoHeight src="/login.svg" layout="fill" alt="Main Image" />
        </Box>
        <Box color="white">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="6rem" fontWeight="500" lineHeight="1">
              500
            </Text>
            <LanguageSwitcher />
          </Flex>
          <Text
            fontSize="1.875rem"
            mt="1.25rem"
            fontWeight="500"
            lineHeight="2.25rem"
          >
            {t("error.general")}
          </Text>
          <Text fontSize="1.125rem" mt=".75rem" lineHeight="1.75rem">
            {t("error.generalText")}
          </Text>
          <Button onClick={onGoBack} variant="dark" size="xl" mt="2.5rem">
            {t("error.back")}
          </Button>
          <Button
            onClick={onRefresh}
            variant="dark"
            size="xl"
            mt="2.5rem"
            ml="0.5rem"
          >
            {t("refresh")}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => ({
  props: {
    ...(await serverSideTranslations(ctx.locale, ["common"])),
  },
});

export default Custom500Page;
