import { Session } from "next-auth";
import { signOut, useSession } from "next-auth/client";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { mutate } from "swr";
import useSWRImmutable from "swr/immutable";

export const mutateSession = async (): Promise<void> => {
  await mutate("/auth/me");
};

export const clearCache = async (): Promise<void> => {
  mutate(
    (key) => true, // which cache keys are updated
    undefined, // update cache data to `undefined`
    { revalidate: false } // do not revalidate
  );
};

export function useUser({ redirectTo = null, redirectIfFound = false } = {}): [
  session: Session,
  isLoading: boolean,
  isError: boolean
] {
  const router = useRouter();
  const [nextSession, loading] = useSession();

  const { data: session, isValidating, error } = useSWRImmutable("/auth/me");

  const hasSession = !!nextSession?.user?.id;
  const isLoading = loading || (!session && isValidating);

  useEffect(() => {
    if (!nextSession || nextSession.user.id || loading) return;
    signOut({ redirect: false });
  }, [nextSession, loading]);

  useEffect(() => {
    if (!redirectTo || isLoading) return;
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasSession) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasSession)
    ) {
      router.push(redirectTo);
    }
  }, [redirectTo, redirectIfFound, hasSession, isLoading]);

  return [hasSession ? session : null, isLoading, !!error];
}
