export default {
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Roboto', sans-serif",
  },
  colors: {
    main: {
      800: "#141c66",
      700: "#283398",
      600: "#4752b8",
    },
    light: {
      200: "#dfe1e3",
      100: "#f5f5f5",
      50: "#f5f5f5",
      10: "#FFFFFF",
    },
    dark: {
      800: "#434343",
    },
    text: {
      900: "#2d3748",
      700: "#4a5568",
      500: "#718096",
      200: "#d8d8d8",
      100: "#FFFFFF",
    },
    red: {
      500: "#d32929",
    },
    green: {
      500: "#91c714",
    },
    orange: {
      500: "#fbc500",
    },
    blue: {
      500: "#141c66",
    },
  },
  styles: {
    global: {
      body: {
        bg: "light.100",
        fontSize: ".875rem",
        lineHeight: "1.25rem",
        color: "text.900",
        overflowX: "hidden",
      },
      ".parsed-html": {
        "ul, ol": {
          marginLeft: { base: "25px", md: "30px" },
        },
        a: {
          color: "main.600",
          fontWeight: "bold",
          _hover: {
            color: "main.800",
          },
        },
        h1: {
          fontSize: "2.25rem",
          lineHeight: 1.33,
        },
        h2: {
          fontSize: "1.875rem",
          lineHeight: 1.33,
        },
        h3: {
          fontSize: "1.5rem",
          lineHeight: 1.33,
        },
        h4: {
          fontSize: "1.25rem",
          lineHeight: 1.33,
        },
        h5: {
          fontSize: "1.125rem",
          lineHeight: 1.33,
        },
        blockquote: {
          borderLeftWidth: 5,
          borderColor: "main.800",
          paddingLeft: "30px",
        },
        ".ql-align-center": {
          textAlign: "center",
          img: {
            marginLeft: "auto",
            marginRight: "auto",
          },
        },
        ".ql-align-justify": {
          textAlign: "justify",
        },
        ".ql-align-right": {
          textAlign: "right",
          img: {
            display: "inline-block",
          },
        },
        ".ql-align-left": {
          textAlign: "left",
        },
        ".ql-indent-1": {
          marginLeft: "30px",
        },
        ".ql-indent-2": {
          marginLeft: "60px",
        },
        ".ql-indent-3": {
          marginLeft: "90px",
        },
        ".ql-indent-4": {
          marginLeft: "120px",
        },
        ".ql-indent-5": {
          marginLeft: "150px",
        },
        ".large": {
          width: "100%",
        },
        ".medium": {
          width: "60%",
        },
        ".small": {
          width: "30%",
        },
      },
      img: {
        maxWidth: "100%",
      },
      a: {
        transition: "all .2s cubic-bezier(.4,0,.2,1)",
        _hover: { textDecoration: "none" },
        img: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
        span: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
        svg: {
          transition: "all .2s cubic-bezier(.4,0,.2,1)",
        },
      },
      "::-webkit-input-placeholder": {
        color: "white",
        opacity: 0.6,
      },
      ":-moz-placeholder": {
        color: "white",
        opacity: 0.6,
      },
      "::-moz-placeholder": {
        color: "white",
        opacity: 0.6,
      },
      ":-ms-input-placeholder": {
        color: "white",
        opacity: 0.6,
      },
      "h3:only-child": {
        mb: 0,
      },
    },
  },

  components: {
    Heading: {
      baseStyle: {
        lineHeight: "1.2",
      },
      sizes: {
        sm: {
          fontWeight: "500",
          color: "white",
          mb: "10px",
        },
        md: {
          fontWeight: "500",
          color: "main.700",
          mb: "15px",
        },
        lg: {
          mb: 6,
          fontWeight: "500",
          fontSize: "26px",
        },
        xl: {
          color: "text.100",
          fontWeight: "normal",
          fontSize: "30px",
        },
      },
    },

    Tag: {
      parts: ["container", "label", "closeButton"],
      baseStyle: {
        container: {
          fontWeight: "normal",
          textTransform: "uppercase",
        },
      },
      sizes: {
        md: {
          container: {
            px: "7.5px",
            py: "2.5px",
            borderRadius: 0,
            fontSize: "11px",
          },
        },
      },
      variants: {
        subtle: {
          container: {
            bg: "main.800",
            color: "white",
          },
        },
      },
    },

    Input: {
      parts: ["field", "addon"],
      sizes: {
        md: {
          field: {
            fontSize: ".875rem",
            padding: ".5rem 1rem",
            borderRadius: 0,
            lineHeight: "1.15",
          },
        },
      },
      variants: {
        unstyled: {
          field: {
            backgroundColor: "#FFFFFF",
            borderWidth: "1px",
            borderColor: "light.300",
            _focus: {
              borderColor: "main.600",
            },
            _disabled: {
              bg: "gray.100",
            },
            _readOnly: {
              cursor: "default",
              pointerEvents: "none",
            },
            _invalid: {
              borderColor: "red.500",
            },
          },
        },
        header: {
          field: {
            backgroundColor: "main.700",
            color: "white",
            borderWidth: 0,
          },
        },
      },
      defaultProps: {
        variant: "unstyled",
      },
    },

    Button: {
      baseStyle: {
        fontWeight: "500",
        borderRadius: 0,
        transitionProperty:
          "background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter",
        transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
        transitionDuration: ".2s",
        fontSize: "14px",
        _disabled: {
          pointerEvents: "none",
          cursor: "default",
        },
      },
      sizes: {
        md: {
          py: 4,
          px: 6,
          lineHeight: 1.15,
        },
      },
      variants: {
        solid: {
          backgroundColor: "main.800",
          color: "white",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            backgroundColor: "main.700",
          },
          _active: {
            backgroundColor: "main.700",
          },
        },
        outline: {
          _focus: {
            boxShadow: "none",
          },
        },
        header: {
          backgroundColor: "main.700",
          borderWidth: 0,
          color: "text.200",
          _hover: {
            color: "white",
          },
          _focus: {
            boxShadow: "none",
          },
          _active: {
            color: "white",
          },
        },
      },
    },

    Switch: {
      parts: ["track", "thumb"],
      baseStyle: {
        track: {
          bg: "light.200",
          _checked: {
            bg: "main.800",
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },

    Link: {
      baseStyle: {
        color: "main.700",
        fontWeight: "500",
        _hover: {
          color: "text.900",
          textDecoration: "none",
        },
        _focus: {
          boxShadow: "none",
        },
      },
    },

    FormLabel: {
      baseStyle: {
        display: "inline-block",
        fontWeight: "normal",
        color: "black",
        mr: 0,
        pb: ".5rem",
        fontSize: ".875rem",
        mb: 0,
      },
    },

    FormError: {
      parts: ["text", "icon"],
      baseStyle: {
        text: {
          marginTop: "5px",
          fontSize: "12px",
        },
      },
    },

    Modal: {
      parts: [
        "dialogContainer",
        "dialog",
        "header",
        "closeButton",
        "body",
        "footer",
      ],
      baseStyle: {
        dialog: {
          borderRadius: 0,
          my: { base: 0, md: "3.75rem" },
          color: "black",
        },
        header: {
          fontSize: "1rem",
          fontWeight: "500",
          borderBottomColor: "light.50",
          borderBottomWidth: "1px",
          px: { base: 4, md: 6 },
        },
        body: {
          px: { base: 4, md: 6 },
        },
      },
    },

    Avatar: {
      parts: ["container", "excessLabel", "badge", "label"],
      baseStyle: {
        container: {
          transitionProperty: "background-color",
          transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
          transitionDuration: ".2s",
        },
      },
      sizes: {
        sm: {
          container: {
            w: "30px",
            h: "30px",
          },
        },
      },
      defaultProps: {
        size: "sm",
      },
    },

    Tooltip: {
      baseStyle: {
        p: ".375rem .75rem",
        borderRadius: 0,
        fontSize: "14px",
        lineHeight: 1.4,
        bg: "#333",
        boxShadow: "none",
        color: "white",
        fontWeight: "normal",
      },
    },

    Accordion: {
      parts: ["container", "button", "panel", "icon"],
      baseStyle: {
        container: {
          borderColor: "light.200",
        },
        button: {
          p: 3,
          fontWeight: "500",
          fontSize: "1.125rem",
          color: "text.900",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: "transparent",
          },
          _expanded: {
            color: "text.900",
          },
        },
        panel: {
          px: 0,
          pt: 0,
          pb: 3,
        },
      },
    },

    Menu: {
      parts: ["item", "command", "list", "button", "groupTitle", "divider"],
      baseStyle: {
        item: {
          padding: ".5rem",
          borderRadius: 0,
          color: "black",
          transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
          transitionDuration: ".3s",
          transitionProperty:
            "background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter",
          _hover: {
            color: "text.100",
            bg: "light.200",
          },
          _active: {
            color: "text.100",
            bg: "light.200",
          },
          _focus: {
            color: "text.100",
            bg: "light.200",
          },
        },
        list: {
          mt: ".25rem",
          boxShadow: "0 3px 10px rgb(0, 0, 0, 0.09)",
          borderWidth: 0,
          borderRadius: 0,
          p: ".5rem",
          color: "black",
        },
        divider: {
          my: ".5rem",
          borderColor: "light.200",
        },
      },
    },

    Alert: {
      parts: ["container", "title", "description", "icon"],
      baseStyle: {
        container: {
          borderRadius: 0,
        },
      },
      defaultProps: {
        variant: "solid",
      },
    },
  },
};
