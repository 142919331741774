import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import axios from "axios";

import useNeedsSubscription from "@/hooks/useNeedsSubscription";
import { useUser } from "@/hooks/useUser";

import { OfficeType, OfficeUserRole } from "../types/office";
import { useSettings } from "./SettingsContext";
import { UserRole } from "../types/user";

export const updateProfile = async (profile): Promise<any> => {
  return axios.patch("/profile", profile);
};

type ValidType = "user" | OfficeType;
type ValidRole = UserRole | OfficeUserRole;

const withAuthentication = (
  WrappedComponent: React.FC,
  type?: ValidType | ValidType[],
  role?: ValidRole | ValidRole[]
) => {
  const RequiresAuthentication = (props) => {
    const router = useRouter();
    const [session, loading] = useUser();
    const [, setCookie] = useCookies(["NEXT_LOCALE"]);
    const { settings, patchSettings } = useSettings();
    const { needsSubscription } = useNeedsSubscription();

    const [show, setShow] = useState(!!session?.user.id);
    const isLoading = settings?.isLoading || settings?.isPageLoading || loading;
    const currentRole = session?.currentRole;
    const { locale, asPath } = router;

    useEffect(() => {
      const userLocale = session?.user?.profile?.locale;
      if (userLocale === null) {
        router.push(asPath, asPath, { locale });
        updateProfile({ locale });
      } else if (userLocale !== locale) {
        if (navigator.cookieEnabled)
          setCookie("NEXT_LOCALE", userLocale, { path: "/" });
        router.push(asPath, asPath, { locale: userLocale });
      }

      const menuCollapsed = session?.user?.profile?.menuCollapsed;
      if (menuCollapsed && settings?.menuCollapsed === null)
        patchSettings({ menuCollapsed });

      settings.setSession(session);
    }, []);

    useEffect(() => {
      const menuCollapsed = session?.user?.profile?.menuCollapsed;
      if (
        settings.menuCollapsed !== null &&
        menuCollapsed !== settings.menuCollapsed
      )
        updateProfile({ menuCollapsed: settings.menuCollapsed });
    }, [settings?.menuCollapsed]);

    useEffect(() => {
      settings.setSession(session);
    }, [session]);

    useEffect(() => {
      if (isLoading) return;
      if (!session?.user?.id) {
        const query: { token?: string; from?: string } = {};
        if (router.query.token) query.token = router.query.token as string;
        if (asPath !== "/") query.from = asPath;

        router.push({
          pathname: "/signin",
          query,
        });
      } else if (
        currentRole &&
        needsSubscription &&
        !currentRole.subscribed &&
        (currentRole.type == OfficeType.ORGANIZATION ||
          currentRole.type == OfficeType.PRESS)
      ) {
        router.push({ pathname: "/payments" });
      } else if (
        !!session?.user?.id &&
        ((role && !role.includes(currentRole?.role)) ||
          (type && !type.includes(currentRole?.type)))
      ) {
        router.push({ pathname: "/" });
      } else if (session?.user.id) setShow(true);
    }, [session, needsSubscription, isLoading]);

    if (!show) return null;

    return <WrappedComponent {...props} />;
  };

  RequiresAuthentication.getLayout = WrappedComponent.getLayout;

  return RequiresAuthentication;
};

export default withAuthentication;
