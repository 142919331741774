import { Language, LanguageShort } from "@/types/common";

export const languages = [Language.POLISH, Language.ENGLISH, Language.RUSSIAN];

export const flags: { [key in Language]: string } = {
  [Language.POLISH]: "Poland.png",
  [Language.ENGLISH]: "United-Kingdom.png",
  [Language.RUSSIAN]: "Russia.png",
};

export const getFlag = (language: string): string =>
  flags[language] ?? "Unknown.png";

export const languagesShort = [
  LanguageShort.PL,
  LanguageShort.EN,
  LanguageShort.RU,
];

export const flagsShort: { [key in LanguageShort]: string } = {
  [LanguageShort.PL]: "Poland.png",
  [LanguageShort.EN]: "United-Kingdom.png",
  [LanguageShort.RU]: "Russia.png",
};

export const getFlagShort = (languagesShort: string): string =>
  flagsShort[languagesShort] ?? "Unknown.png";

export const languageTranslations: { [key in Language]: string } = {
  [Language.POLISH]: "languages.polish",
  [Language.ENGLISH]: "languages.english",
  [Language.RUSSIAN]: "languages.russian",
};

export const languageTranslationsShort: { [key in LanguageShort]: string } = {
  [LanguageShort.PL]: "languages.polish",
  [LanguageShort.EN]: "languages.english",
  [LanguageShort.RU]: "languages.russian",
};

export const getTranslation = (language: string): string =>
  languageTranslations[language] ?? "languages.languageNotFound";
